import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";

import Header from "@components/Header";
import Footer from "@components/Footer";
import Seo from "@components/Seo";

import "@loadingio/loading.css/dist/loading.min.css";
import "animate.css";

const ContactPage = () => {
  const [isSubmittedContactForm, setIsSubmittedContactForm] = useState(false);
  const [isSubmittedNewsletterForm, setIsSubmittedNewsletterForm] =
    useState(false);

  const onSubmitContactForm = (values) => {
    let request = new Request("https://app.99inbound.com/api/e/Wo92Z6f2");

    return fetch(request, {
      method: "post",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return {
            [FORM_ERROR]: "Something went wrong please try again...",
          };
        }
        setIsSubmittedContactForm(true);
      })
      .catch((err) => {
        setIsSubmittedContactForm(false);
        return {
          [FORM_ERROR]: "Something went wrong please try again...",
        };
      });
  };
  const onSubmitNewsletterForm = (values) => {
    let request = new Request("https://app.99inbound.com/api/e/hOzbCflc");

    return fetch(request, {
      method: "post",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return {
            [FORM_ERROR]: "Something went wrong please try again...",
          };
        }
        setIsSubmittedNewsletterForm(true);
      })
      .catch((err) => {
        setIsSubmittedNewsletterForm(false);
        return {
          [FORM_ERROR]: "Something went wrong please try again...",
        };
      });
  };

  // const validate = (values) => {
  //   // const errors = {};
  //   // if (values.message?.length < 3) {
  //   //   errors.message = "Please enter a valid message...";
  //   // }
  //   // return errors;
  // };
  const initValues = {};
  return (
    <>
      <Seo title="Contact us" />
      <main className="contactPage">
        <div className="contactPage__header">
          <Header />
        </div>
        <div className="contactPage__side-image animate__animated animate__fadeInRight">
          <StaticImage
            src="../assets/images/mall.png"
            alt="a mall image"
            width={420}
            height={670}
          />
        </div>
        <div class="contactPage__container">
          <div className="contactPage__form">
            <small className="animate__animated animate__fadeInUp">
              contact
            </small>
            <h1 className="contactPage__title animate__animated animate__fadeInUp">
              talk to <span>us</span>
            </h1>
            <Form
              onSubmit={onSubmitContactForm}
              // initialValues={initValues}
              // validate={validate}
            >
              {({ handleSubmit, form, submitting, submitError }) => (
                <form onSubmit={handleSubmit} className="form">
                  {submitError && (
                    <div className="form__submit-error">{submitError}</div>
                  )}
                  <div
                    className={`form__successful ${
                      isSubmittedContactForm &&
                      "animate__animated animate__fadeIn pointer-events-all"
                    }`}
                  >
                    Thank you for contacting us. We'll get back to you.{" "}
                    <a href="/contact"> Go back </a>
                  </div>
                  <div
                    className={`form__container ${
                      isSubmittedContactForm
                        ? "animate__animated animate__fadeOut pointer-events-none"
                        : ""
                    }`}
                  >
                    <div className="form__col form__col--2">
                      <Field name="firstname">
                        {({ input, meta }) => (
                          <div>
                            <label>FIRST NAME</label>
                            <input type="text" {...input} required />
                            {meta.error && (
                              <span class="form__error">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="lastname">
                        {({ input, meta }) => (
                          <div>
                            <label>LAST NAME</label>
                            <input type="text" {...input} required />
                            {meta.error && (
                              <span class="form__error">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="form__col form__col--2">
                      <Field name="phone">
                        {({ input, meta }) => (
                          <div>
                            <label>PHONE NUMBER</label>
                            <input type="number" {...input} required />
                            {meta.error && (
                              <span class="form__error">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                      <Field name="email">
                        {({ input, meta }) => (
                          <div>
                            <label>EMAIL ADDRESS</label>
                            <input type="email" {...input} required />
                            {meta.error && (
                              <span class="form__error">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="form__col">
                      <Field name="message">
                        {({ input, meta }) => (
                          <div>
                            <label>MESSAGE</label>
                            <input type="text" {...input} required />
                            {meta.error && meta.touched && (
                              <span class="form__error">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div class="form__submit-container">
                      <button
                        type="submit"
                        className={`form__submit link-underline-hover ${
                          submitting && "ld ld-fade"
                        }`}
                      >
                        {submitting ? "submitting..." : "SUBSCRIBE"}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Form>
          </div>
          <div className="contactPage__bottom">
            <div className="contactPage__newsletter">
              <h1 className="contactPage__title">
                stay <span>in touch</span>
              </h1>
              <p>Subscribe to our newsletter to keep in touch</p>
              <Form onSubmit={onSubmitNewsletterForm}>
                {({ handleSubmit, submitting, submitError }) => (
                  <form onSubmit={handleSubmit} className="form">
                    {submitError && (
                      <div className="form__submit-error">{submitError}</div>
                    )}
                    <div
                      className={`form__successful ${
                        isSubmittedNewsletterForm &&
                        "animate__animated animate__fadeIn pointer-events-all"
                      }`}
                    >
                      Thanks for subscribing. We'll be in your inbox soon.
                    </div>
                    <div
                      className={`form__container ${
                        isSubmittedNewsletterForm
                          ? "animate__animated animate__fadeOut pointer-events-none"
                          : ""
                      }`}
                    >
                      <Field name="newsletter-email">
                        {({ input, meta }) => (
                          <input
                            type="email"
                            required
                            {...input}
                            className="form__newsletter-input"
                          />
                        )}
                      </Field>
                      <div class="form__submit-container">
                        <button
                          type="submit"
                          className={`form__submit link-underline-hover ${
                            submitting && "ld ld-fade"
                          }`}
                        >
                          {submitting ? "submitting..." : "SUBMIT"}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Form>
            </div>
            <div className="contactPage__contact-info">
              <div className="contactPage__contact-item">
                <p className="font-bold text-dim">OFFICE</p>
                <p className="font-bold">AI Apartment 508</p>
                <p>1004 Federal Housing</p>
                <p>Complete Victoria Island</p>
              </div>
              <div className="contactPage__contact-item">
                <p className="font-bold text-dim">SUPPORT</p>
                <p className="font-bold">+234 809 645 4003</p>
                <p>
                  <a
                    className="font-bold text-grey text-decoration-none"
                    href="mailto:info@realprojects.com"
                  >
                    info@realprojects.co
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default ContactPage;
